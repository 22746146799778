import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Login' }
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "applications" */ '../views/Applications.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "applications" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/applications-admin',
    name: 'Applications (Admin)',
    component: () => import(/* webpackChunkName: "users" */ '../views/ApplicationsAdmin.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "users" */ '../views/Files.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:userId/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "text-green-600 font-bold -text-gray-400"
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token')) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
